// src/pages/_app.tsx
import React, { useState, useEffect, useMemo, createContext } from 'react';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import Script from 'next/script';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '../../src/createEmotionCache';
import { MyAppProps } from '../types/myAppProps'; // Import the custom props interface

// Import global styles
import '../styles/globals.css';
import '../styles/globals.scss';
import '../styles/layout.scss';

// Create Context for Color Mode
export const ColorModeContext = createContext({ toggleColorMode: () => {} });

// Client-side cache, shared for the whole session of the user in the browser
const clientSideEmotionCache = createEmotionCache();

function MyApp(props: MyAppProps) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
  } = props;

  // Initialize mode from localStorage or system preference
  const [mode, setMode] = useState<'light' | 'dark'>(() => {
    if (typeof window !== 'undefined') {
      const savedMode = localStorage.getItem('themeMode');
      if (savedMode === 'light' || savedMode === 'dark') {
        return savedMode as 'light' | 'dark';
      } else {
        const prefersDarkMode = window.matchMedia(
          '(prefers-color-scheme: dark)'
        ).matches;
        return prefersDarkMode ? 'dark' : 'light';
      }
    } else {
      return 'light';
    }
  });

  useEffect(() => {
    // Update localStorage whenever mode changes
    if (typeof window !== 'undefined') {
      localStorage.setItem('themeMode', mode);

      // Update the 'dark' class on <html> element for Tailwind CSS
      if (mode === 'dark') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#0d47a1',
          },
          background: {
            default: mode === 'light' ? '#f5f5f5' : '#121212',
            paper: mode === 'light' ? '#ffffff' : '#1e1e1e',
          },
          text: {
            primary: mode === 'light' ? '#000000' : '#ffffff',
          },
        },
        typography: {
          fontFamily: 'Roboto, sans-serif',
        },
      }),
    [mode]
  );

  return (
    <CacheProvider value={emotionCache}>
      {/* Global meta tags */}
      <Head>
        {/* Viewport meta tag */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />

        {/* Charset and SEO meta tags */}
        <meta charSet="UTF-8" />
        <meta name="description" content="Your site description" />
        <meta name="keywords" content="your, keywords, here" />
        <meta name="author" content="Your Name" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Site Title" />
        <meta property="og:description" content="Your site description" />
        <meta property="og:image" content="/path-to-your-og-image.jpg" />
        <meta property="og:url" content="https://your-site-url.com" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Site Title" />
        <meta name="twitter:description" content="Your site description" />
        <meta name="twitter:image" content="/path-to-your-twitter-image.jpg" />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {/* Load the Cloudflare Turnstile script lazily */}
      <Script
        src="https://challenges.cloudflare.com/turnstile/v0/api.js"
        strategy="lazyOnload"
        onLoad={() => console.log('Turnstile script loaded successfully.')}
        onError={(e) => console.error('Failed to load Turnstile script:', e)}
      />

      {/* Provide the theme and color mode to the application */}
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* Render the main component */}
          <Component {...pageProps} />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </CacheProvider>
  );
}

// Wrap the app with i18n translation support
export default appWithTranslation(MyApp);